let url;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  //url = 'https://api.sanoh.kelola-app.biz/api'
  //localhost sendiri
  // url = "http://192.168.18.14:8000/api";
  //ato
  // url = "http://192.168.100.242:8000/api";
  //url = 'http://192.168.1.86:8000/api'
  //rega
  //url='http://192.168.100.41:8000/api'
  //url='http://192.168.1.47:8000/api'
  //langsung backend sendiri
  // url = 'http://127.0.0.1:8001/api'
  url = "https://api.warehouse-sto.kelola.biz/api";
  //   url = "http://10.1.10.104:8000/api";
} else {
  url = "https://api.warehouse-sto.kelola.biz/api";
}

export { url };

import React from "react";
import BasicLayout from "./layouts/BasicLayout";
import Login from "./views/Login/Login";
import { Redirect } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import CheckSheetLayout from "./layouts/CheckSheetLayout";
import Dashboard from "./views/Dashboard/Dashboard";
import User from "./views/User/User";
import CreateUser from "./views/User/CreateUser";
import EditUser from "./views/User/EditUser";
import Permission from "./views/Permission/Permission";
import CreatePermission from "./views/Permission/CreatePermission";
import EditPermission from "./views/Permission/EditPermission";
import ViewPermission from "./views/Permission/ViewPermission";
import Role from "./views/Role/Role";
import CreateRole from "./views/Role/CreateRole";
import EditRole from "./views/Role/EditRole";
import Receiving from "./views/Receiving/Receiving";
import Outgoing from "./views/Outgoing/Outgoing";
import FinishGoods from "./views/FinishGoods/FinishGoods";
import Invoice from "./views/Invoice/Invoice";
import DeliveryNote from "./views/DeliveryNote/DeliveryNote";
import Shopping from "./views/Shopping/Shopping";
import SalesPrice from "./views/SalesPrice/SalesPrice";
import Setting from "./views/Setting/Setting";
import CreateSetting from "./views/Setting/CreateSetting";
import EditSetting from "./views/Setting/EditSetting";
import Wip from "./views/Wip/Wip";
import WipService from "./views/WipService/WipService";
import EditSalesPrice from "./views/SalesPrice/EditSalesPrice";
import ViewSalesPrice from "./views/SalesPrice/ViewSalesPrice";
import TempSalesPrice from "./views/SalesPrice/TempSalesPrice";
import UploadInvoice from "./views/Invoice/UploadInvoice";
import Import from "./views/Import/Import";
import ViewOutgoing from "./views/Outgoing/ViewOutgoing";
import ViewDeliveryNote from "./views/DeliveryNote/ViewDeliveryNote";
import Group from "./views/Group/Group";
import CreateGroup from "./views/Group/CreateGroup";
import EditGroup from "./views/Group/EditGroup";
import ItemGroup from "./views/ItemGroup/ItemGroup";
import CreateItemGroup from "./views/ItemGroup/CreateItemGroup";
import EditItemGroup from "./views/ItemGroup/EditItemGroup";
import UploadItemGroup from "./views/ItemGroup/UploadItemGroup";
import UploadWip from "./views/WipService/UploadWip";
import WipImport from "./views/WipImport/WipImport";
import JournalStock from "./views/Report/JournalStock";
import ViewJournalStock from "./views/Report/ViewJournalStock";
import ShoppingHistory from "./views/Shopping/ShoppingHistory";
import MatrixKanban from "./views/MatrixKanban/MatrixKanban";
import CreateMatrixKanban from "./views/MatrixKanban/CreateMatrixKanban";
import EditMatrixKanban from "./views/MatrixKanban/EditMatrixKanban";
import WarehouseInventory from "./views/WarehouseInventory/WarehouseInventory";
import ChecksheetWarehouse from "./views/ChecksheetWarehouse/ChecksheetWarehouse";

import GenerateLabelMaterial from "./views/GenerateLabelMaterial/Generate"
import PrintLabelMaterial from "./views/GenerateLabelMaterial/Print"
import GenerateLabelMaterialHistory from "./views/GenerateLabelMaterial/GenerateHistory"
import StockOpname from "./views/StockOpname/StockOpname";
import HistoryBeginning from "./views/HistoryBeginning/HistoryBeginning";
import UploadHistoryBeginning from "./views/HistoryBeginning/UploadHistoryBeginning";
export default [
  {
    path: "/",
    layout: BasicLayout,
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
    exact: true,
  },
  {
    path: "/dashboard",
    layout: BasicLayout,
    component: Dashboard,
    exact: true,
  },
  {
    path: "/group",
    layout: FullLayout,
    component: Group,
    exact: true,
  },
  {
    path: "/group/create",
    layout: FullLayout,
    component: CreateGroup,
    exact: true,
  },
  {
    path: "/group/edit/:id",
    layout: FullLayout,
    component: EditGroup,
    exact: true,
  },
  {
    path: "/item-group",
    layout: FullLayout,
    component: ItemGroup,
    exact: true,
  },
  {
    path: "/item-group/create",
    layout: FullLayout,
    component: CreateItemGroup,
    exact: true,
  },
  {
    path: "/item-group/edit/:id",
    layout: FullLayout,
    component: EditItemGroup,
    exact: true,
  },
  {
    path: "/item-group/upload",
    layout: FullLayout,
    component: UploadItemGroup,
    exact: true,
  },
  {
    path: "/matrix-kanban",
    layout: FullLayout,
    component: MatrixKanban,
    exact: true,
  },
  {
    path: "/matrix-kanban/create",
    layout: FullLayout,
    component: CreateMatrixKanban,
    exact: true,
  },
  {
    path: "/matrix-kanban/edit/:id",
    layout: FullLayout,
    component: EditMatrixKanban,
    exact: true,
  },
  {
    path: "/user",
    layout: FullLayout,
    component: User,
    exact: true,
  },
  {
    path: "/user/create",
    layout: FullLayout,
    component: CreateUser,
    exact: true,
  },
  {
    path: "/user/edit/:id",
    layout: FullLayout,
    component: EditUser,
    exact: true,
  },
  {
    path: "/permission",
    layout: FullLayout,
    component: Permission,
    exact: true,
  },
  {
    path: "/permission/create",
    layout: FullLayout,
    component: CreatePermission,
    exact: true,
  },
  {
    path: "/permission/edit/:id",
    layout: FullLayout,
    component: EditPermission,
    exact: true,
  },
  {
    path: "/permission/view/:id",
    layout: FullLayout,
    component: ViewPermission,
    exact: true,
  },
  {
    path: "/role",
    layout: FullLayout,
    component: Role,
    exact: true,
  },
  {
    path: "/role/create",
    layout: FullLayout,
    component: CreateRole,
    exact: true,
  },
  {
    path: "/role/edit/:id",
    layout: FullLayout,
    component: EditRole,
    exact: true,
  },
  {
    path: "/scan-receiving-wh",
    layout: FullLayout,
    component: Receiving,
    exact: true,
  },
  {
    path: "/scan-outgoing-wh",
    layout: FullLayout,
    component: Outgoing,
    exact: true,
  },
  {
    path: "/scan-outgoing-wh/view/:id",
    layout: FullLayout,
    component: ViewOutgoing,
    exact: true,
  },
  {
    path: "/finish-goods",
    layout: FullLayout,
    component: FinishGoods,
    exact: true,
  },
  {
    path: "/scan-wip",
    layout: FullLayout,
    component: Wip,
    exact: true,
  },
  {
    path: "/warehouse-receiving-import",
    layout: FullLayout,
    component: Import,
    exact: true,
  },
  {
    path: "/wip-service",
    layout: FullLayout,
    component: WipImport,
    exact: true,
  },
  {
    path: "/wip-service-upload",
    layout: FullLayout,
    component: WipService,
    exact: true,
  },
  {
    path: "/invoice",
    layout: FullLayout,
    component: Invoice,
    exact: true,
  },
  {
    path: "/invoice/upload",
    layout: FullLayout,
    component: UploadInvoice,
    exact: true,
  },
  {
    path: "/wip-service/upload",
    layout: FullLayout,
    component: UploadWip,
    exact: true,
  },
  {
    path: "/delivery-note",
    layout: FullLayout,
    component: DeliveryNote,
    exact: true,
  },
  {
    path: "/delivery-note/report",
    layout: FullLayout,
    component: ViewDeliveryNote,
    exact: true,
  },
  {
    path: "/journal-stock",
    layout: FullLayout,
    component: JournalStock,
    exact: true,
  },
  {
    path: "/journal-stock-details/view/:id",
    layout: FullLayout,
    component: ViewJournalStock,
    exact: true,
  },
  {
    path: "/shopping-scan",
    layout: FullLayout,
    component: Shopping,
    exact: true,
  },
  {
    path: "/sales-price",
    layout: FullLayout,
    component: SalesPrice,
    exact: true,
  },
  {
    path: "/sales-price/temp-sales-price",
    layout: FullLayout,
    component: TempSalesPrice,
    exact: true,
  },
  {
    path: "/sales-price/edit/:id",
    layout: FullLayout,
    component: EditSalesPrice,
    exact: true,
  },
  {
    path: "/sales-price/view/:id",
    layout: FullLayout,
    component: ViewSalesPrice,
    exact: true,
  },
  {
    path: "/setting",
    layout: FullLayout,
    component: Setting,
    exact: true,
  },
  {
    path: "/setting/create",
    layout: FullLayout,
    component: CreateSetting,
    exact: true,
  },
  {
    path: "/setting/edit/:id",
    layout: FullLayout,
    component: EditSetting,
    exact: true,
  },
  {
    path: "/shopping-history",
    layout: FullLayout,
    component: ShoppingHistory,
    exact: true,
  },
  {
    path: "/warehouse-inventory",
    layout: FullLayout,
    component: WarehouseInventory,
    exact: true
  },
  {
    path: "/checksheet-warehouse",
    layout: CheckSheetLayout,
    component: ChecksheetWarehouse,
    exact: true
  },
  
  {
    path: "/generate-labelmaterial",
    layout: FullLayout,
    component: GenerateLabelMaterial,
    exact: true,
  },
  {
    path: "/generate-labelmaterial/report",
    layout: FullLayout,
    component: GenerateLabelMaterialHistory,
    exact: true,
  },
  {
    path: "/printLabelMaterial",
    layout: PrintLabelMaterial,
    exact: true,
  },
  {
    path: "/stock-opname",
    layout: FullLayout,
    component: StockOpname,
    exact: true
  },
  {
    path: "/history-beginning",
    layout: FullLayout,
    component: HistoryBeginning,
    exact: true
  },
  {
    path: "/history-beginning/upload",
    layout: FullLayout,
    component: UploadHistoryBeginning,
    exact: true
  }
];

import React, { useState, useEffect, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Row,Col,Breadcrumb,Button,Input,Table, message, Modal} from 'antd';
import {Link,Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons'
//import './User.less'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function Role() {
    const [buttonAccess, setButtonAccess] = useState([]);
    const [keyword, setKeyword] = useState('')
    const [role, setRole] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'description',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    useEffect(() => {
        getData()
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            sessionStorage.getItem('permissions') &&
            JSON.parse(sessionStorage.getItem('permissions'));
        
        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.type === 'Button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.type === 'Button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, sort])

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/role`, {
            params: {
                keyword,
                columns: [
                    'name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setRole(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {

        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {

        setLoading(true)
        Axios.delete(`${url}/role/${id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const check_permission = (permission_button, name) => {
        let permission = permission_button
        ? permission_button.find(permission_upload_invoice => 
            permission_upload_invoice !== null && permission_upload_invoice.name 
            == name
            && permission_upload_invoice.allow == "true")
        : false;

        return permission;
    }

    let permission_add_role = check_permission(buttonAccess, "Add Role");
    let permission_edit_role = check_permission(buttonAccess, "Edit Role");
    let permission_delete_role = check_permission(buttonAccess, "Delete Role");

    if(permission_add_role && permission_add_role.allow === "true"){
        permission_add_role = true;
    }else {
        permission_add_role = false;
    }

    if(permission_edit_role && permission_edit_role.allow === "true"){
        permission_edit_role = true;
    }else {
        permission_edit_role = false;
    }
    if(permission_delete_role && permission_delete_role.allow === "true"){
        permission_delete_role = true;
    }else {
        permission_delete_role = false;
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }


    const columns = [
        {
            title: 'Role',
            dataIndex: 'name',
            sorter: true
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true
        },
        {
          title: '',
          dataIndex: '_id',
          align: 'right',
          sorter: false,
          fixed: 'right',
          width: 100,
            render: (id) => {
                if(permission_edit_role || permission_delete_role){
                    return (
                         <Fragment>
                            <Dropdown className="pointer" overlay={
                                 <Menu>
                                    {/* <Menu.Item key="0">
                                        <Link to={`/role/view/${id}`}><InfoCircleOutlined /> View</Link>
                                    </Menu.Item> */}
                                    <Menu.Item key="1">
                                        <Link to={`/role/edit/${id}`}><EditOutlined /> Edit</Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <span onClick={() => handleDelete(id) }><DeleteOutlined />Delete</span>
                                    </Menu.Item>
                                </Menu>
                            } trigger={['click']}>
                                <span className="pointer" onClick={e => e.preventDefault()}>
                                    <MoreOutlined />
                                </span>
                            </Dropdown>
                        </Fragment>
                    )
                }else {
                    return('')
                }
            }
        }
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Role</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Role</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                                
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                    <Col xs={24} md={12} lg={18}>
                                        {permission_add_role && (
                                            <Link to="/role/create">
                                                <Button type="primary"><PlusOutlined />Add Role</Button>
                                            </Link>
                                        )}
                                    </Col>
                                    <Col xs={24} md={12} lg={6} className="align-right">
                                        <Search
                                            placeholder="Search ..."
                                            onSearch={(value) => handleSearch(value)}
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <Table
                                        columns={columns}
                                        dataSource={role.data}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{ pageSize: 10, current: page, total: role.total }}
                                        loading={loading}
                                        />   
                                    </Col>
                            </Row>
                               
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}


export default Role
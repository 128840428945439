import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message, Space, Tooltip} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined} from '@ant-design/icons'
import moment from 'moment';
import { render } from 'less';
import DetailStockOpname from './Component/DetailStockOpname';
import ChildComponent from './Component/ChildComponent';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

const StockOpname = () => {
    const [warehouses, setWarehouses] = useState([]); // Use this for the table data
    const [area, setArea] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [dateColumns, setDateColumns] = useState([]);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend'
    })
    const [monthNow, setMonthNow] = useState(moment());
    const [keyword, setKeyword] = useState('')
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [item, setItem] = useState({
        id: '',
        part_no_erp: '',
        description: '',
        qty: '',
        jenis_material_id: '',
        jenis_material_name: '',
        part_status_id: '',
        part_status_name: '',
        area_id: '',
        area_name: '',
        remarks: '',
    })
    const [buttonAccess, setButtonAccess] = useState(null);
    const check_permission = (permission_button, name) => {
      let permission = permission_button
        ? permission_button.find(permission_upload_invoice => 
            permission_upload_invoice !== null && permission_upload_invoice.name 
            == name
            && permission_upload_invoice.allow == "true")
        : false;

      return permission;
    }

    let permission_stock_opname_leader_check = check_permission(buttonAccess, "Stock Opname Leader Check");
    let permission_stock_opname_printed = check_permission(buttonAccess, "Stock Opname Printed");
    const baseColumns = [
        {
          title: 'NO',
          dataIndex: 'no',
          key: 'no',
          align: 'center',
          width:100,
          // fixed: 'left',
          render: (text, record, index) => {
            return (pagination.current - 1) * pagination.pageSize + index + 1;
          }
        },
        {
          title:'SOURCING',
          dataIndex:'sourcing',
          key:'sourcing',
          align: 'center',
        },
        {
          title:'D.I.V',
          dataIndex:'division',
          key:'division',
          align: 'center',
        },
        {
          title:'STATUS',
          dataIndex:'status',
          key:'status',
          align: 'center',
          render: (text,record,index) => {
            return `${record.status}%`;
          }
        },
        {
          title:'CATEGORY',
          dataIndex:'category',
          key:'category',
          align: 'center',
        },
        {
          title: 'PART NO ERP',
          dataIndex: 'part_no_erp',
          key: 'part_no_erp',
          width:200,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'PART NO OLD',
          dataIndex: 'part_no_old',
          key: 'part_no_old',
          width:100,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          width:150,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'SNP',
          dataIndex: 'snp',
          key: 'snp',
          width:100,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'STO',
          dataIndex: 'sto_actual',
          key: 'sto_actual',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            return formattedNumber(record.sto_actual ?? 0);
          }
        },
        {
          title: 'STOCK END LAST MONTH',
          dataIndex: 'stock_end_last_month',
          key: 'stock_end_last_month',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            return formattedNumber(record.stock_end_last_month ?? 0);
          }
        },
        {
          title: 'ACTUAL INCOMING',
          dataIndex: 'actual_incoming',
          key: 'actual_incoming',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            const totalReceiving = record.receivings;
            return formattedNumber(totalReceiving ?? 0);
          }
        },
        {
          title: 'ACTUAL OUTGOING',
          dataIndex: 'actual_outgoing',
          key: 'actual_outgoing',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            const totalOutgoing = record.outgoings;
            return formattedNumber(totalOutgoing ?? 0);
          }
        },
        {
          title: 'BALANCE',
          dataIndex: 'balance',
          key: 'balance',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            // const stockEndLastMonth = record.stock_end_last_month;
            // const totalReceiving = record.receivings;
            // const totalOutgoing = record.outgoings;
            // const balance = (stockEndLastMonth + totalReceiving) - totalOutgoing;
            return formattedNumber(record.balance ?? 0);
          }
        },
        {
          title: 'DIFF',
          dataIndex: 'dif',
          key: 'dif',
          width:100,
          // fixed: 'left',
          align: 'center',
          render: (text, record, index) =>{
            // const stockEndLastMonth = record.stock_end_last_month;
            // const sto_actual = record.sto_actual;
            // const totalReceiving = record.receivings;
            // const totalOutgoing = record.outgoings;
            // const balance = (stockEndLastMonth + totalReceiving) - totalOutgoing;
            // let diff = '';
            // let color = '';
            // if(balance > sto_actual){
            //   color = 'red';
            //   diff = balance - sto_actual;
            // }else if (balance < sto_actual){
            //   color = 'yellow';
            //   diff = sto_actual - balance;
            // }else {
            //   color = 'black';
            //   diff = 0;
            // }
            return (
              <div style={{ color: `${record.color}` }}>{record.diff}</div>
            );
          }
        },
        {
          title: 'MONTH',
          dataIndex: 'month',
          key: 'month',
          width:100,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'YEAR',
          dataIndex: 'year',
          key: 'year',
          width:100,
          // fixed: 'left',
          align: 'center',
        },
        {
          title : 'ACTION',
          align : 'center',
          render: (text,record,index) => {
            if(permission_stock_opname_leader_check || permission_stock_opname_printed){
              return (
                <Fragment>
                  <Dropdown className="pointer" overlay={
                      <Menu>
                          {permission_stock_opname_leader_check && (
                            <Menu.Item key="1" onClick={()=>showModal(record._id)} disabled={record.count_detail === 0}>
                              Leader Check
                            </Menu.Item>
                          )}
                          {
                            isModalVisible && modalId === record._id && (
                              <ChildComponent record={record} area={area} isModalVisible={isModalVisible} okModal={okModal} closeModal={closeModal} item={item}
                                    setItem={setItem} />
                            )
                          }
                          {/* <Menu.Item key="2" onClick={() => handleApproved(record._id)} disabled={record.count_detail === 0}>
                            Approved
                          </Menu.Item> */}
                          {permission_stock_opname_printed && (
                            <Menu.Item key="3" onClick={() => handlePrint(record.part_no_erp)} disabled={record.count_detail === 0}>
                              Printed
                            </Menu.Item>
                          )}
                      </Menu>
                  } trigger={['click']}>
                      <span className="pointer" onClick={e => e.preventDefault()}>
                          <MoreOutlined />
                      </span>
                  </Dropdown>
                </Fragment>
              );
            }else {
              return('');
            }
          }
        },
    ];
    const pagination = {
        pageSize: 10,
        current: page,
        total: warehouses.total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
    };
    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }
    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalId, setModalId] = useState('');

    const showModal = (id) => {
      setModalId(id);
      setIsModalVisible(true);
    };

    const closeModal = () => {
      setModalId('');
      setIsModalVisible(false);
      setItem({
        ...item,
        id: '',
        part_no_erp: '',
        description: '',
        qty: '',
        jenis_material_id: '',
        jenis_material_name: '',
        part_status_id: '',
        part_status_name: '',
        area_id: '',
        area_name: '',
        remarks: '',
      });
    };

    const okModal = async () => {
      console.log(item, "item");
      setModalId('');
      setIsModalVisible(false);
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-sto/submit-leader/${item.id}`,{
        part_no_erp: item.part_no_erp,
        description: item.description,
        qty: item.qty,
        jenis_material: item.jenis_material_id,
        part_status: item.part_status_id,
        area_id:item.area_id,
        area_name:item.area_name,
        remarks: item.remarks,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            id: '',
            part_no_erp: '',
            description: '',
            qty: '',
            jenis_material_id: '',
            jenis_material_name: '',
            part_status_id: '',
            part_status_name: '',
            area_id: '',
            area_name: '',
            remarks: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                message.error(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        getData(keyword, page, sort);
      })
    };
    useEffect(() => {
      getData()
      const pathname = window.location.pathname.split('/');
      console.log(pathname);
      const currentUrl = pathname[1];
      const permissions =
          sessionStorage.getItem('permissions') &&
          JSON.parse(sessionStorage.getItem('permissions'));
      
      if (permissions) {
          const findPermission = permissions.find(
              (permission) => permission.url === currentUrl,
          );

          const findChildPermission = permissions
              .map((child) => {
                  return child.children.find(
                      (grandChild) => grandChild.url === currentUrl,
                  );
              })
              .filter((grandChild) => grandChild)[0];

          if (findChildPermission && findChildPermission.children) {
              if (findChildPermission.children) {
                  const findButtonAccessChildren =
                      findChildPermission.children
                          .filter((x) => x !== null)
                          .filter((x) => x.type === 'Button');
                  if (findButtonAccessChildren) {
                      setButtonAccess(
                          findButtonAccessChildren.filter((x) => x !== null),
                      );
                  }
              }
          }

          if (findPermission && findPermission.children) {
              const findButtonAccess = findPermission.children.filter(
                  (x) => x.type === 'Button',
              );
              if (findButtonAccess) {
                  setButtonAccess(findButtonAccess);
              }
          }
      }
    }, [keyword, page, sort, monthNow])
    const getData = () => {
      console.log("generate data");
        setLoading(true);
        setIsDisabled(true);
        Axios.get(`${url}/warehouse-sto`, {
            params: {
                keyword,
                columns: [
                    'part_no_erp',
                    'part_no_old',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                year : moment(monthNow).format('YYYY'),
                month : moment(monthNow).format('MM'),
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
          console.log(res.data);
          setWarehouses(res.data)
          setArea(res.data.area);
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
            setIsDisabled(false)
        })
    }
    const handleSearch = (value) => {
      setKeyword(value);
      // sessionStorage.setItem("keyword", value);
      setPage(1);
  
      if (value === "") {
        getData();
      }
    };
    const formattedNumber = (value) => {
      return value.toLocaleString('id-ID')
    };
    const handlePrint = async (record) => {
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-sto/submit`, {
        part_no_erp: record,
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      })
      .catch(err => {
        if (err.response.status === 500) {
          message.error('Server error');
        } else {
          if (err.response) {
              const reader = new FileReader();
              reader.onload = function () {
                  const errorMessage = JSON.parse(reader.result);
                  message.error(errorMessage.message);
              };
              reader.readAsText(err.response.data);
          } else {
              console.error('Error:', err.message.message);
          }
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        getData(keyword, page, sort);
      })
    }
    const handleApproved = async (record) => {
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-sto/approved`, {
        _id: record.part_no_erp,
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
        // responseType: 'blob',
      })
      .then((res) => {
        message.success(res.data.message)
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message)
            if (err.response.status == 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      })
    .finally(() => {
        setLoading(false);
        setIsDisabled(false);
        getData(keyword, page, sort);
      })
    }
    const onChange = (date, dateString) => {
      setMonthNow(date);
    };
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }
  return (
    <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>Stock Opname</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>Stock Opname</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" 
                    onClick={getData} 
                    disabled={isDisabled}><ReloadOutlined />Refresh</Button>
                    <DatePicker value={monthNow} onChange={onChange} picker="month" disabled={isDisabled}/>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    defaultValue={sessionStorage.getItem('keyword')}
                    onSearch={(value) => handleSearch(value)}
                    disabled={isDisabled}
                  />
                </Col>
                <Col xs={24}>
                   <Table
                      columns={baseColumns}
                      dataSource={warehouses.data}
                      rowKey={(data) => data._id} /// Make sure this is an array
                      pagination={pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      bordered
                      scroll={{ x: 1000 }}
                      expandable={{
                        expandedRowKeys: expandedRowKeys,
                        onExpand: handleExpand,
                        expandedRowRender: (record) => (
                            <DetailStockOpname
                                key={`detail-${record.original_id}`}
                                id={record.original_id}
                                rows={expandedRowKeys}
                                part_number={record.part_no_erp}
                            />
                        ),
                      }}
                    />
                </Col>  
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
  )
}

export default StockOpname
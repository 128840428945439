import React, { useState, useEffect, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Row,Col,Button,Modal,Input,Table, message, Space} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import moment from 'moment';
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function Group() {
    const [buttonAccess, setButtonAccess] = useState([]);
    const [keyword, setKeyword] = useState('')
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
    }

    useEffect(() => {
        getData()
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            sessionStorage.getItem('permissions') &&
            JSON.parse(sessionStorage.getItem('permissions'));
        
        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.type === 'Button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.type === 'Button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/group`, {
            params: {
                keyword,
                columns: [
                    'name',
                    'description',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setGroups(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {

        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {

        setLoading(true)
        Axios.delete(`${url}/group/${id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true
        },
        {
          title: '',
          dataIndex: '_id',
          align: 'right',
          sorter: false,
          fixed: 'right',
          width: 100,
            render: (id) => {
                if(permission_edit_group || permission_delete_group){
                    return (
                         <Fragment>
                            <Dropdown className="pointer" overlay={
                                 <Menu>
                                    {permission_edit_group && (
                                        <Menu.Item key="1">
                                            <Link to={`/group/edit/${id}`}><EditOutlined /> Edit</Link>
                                        </Menu.Item>
                                    )}
                                    {permission_delete_group && (
                                        <Menu.Item key="3">
                                            <span onClick={() => handleDelete(id) }><DeleteOutlined />Delete</span>
                                        </Menu.Item>
                                    )}
                                </Menu>
                            } trigger={['click']}>
                                <span className="pointer" onClick={e => e.preventDefault()}>
                                    <MoreOutlined />
                                </span>
                            </Dropdown>
                        </Fragment>
                    )
                }else {
                    return('')
                }
            }
        }
    ]

    const check_permission = (permission_button, name) => {
        let permission = permission_button
        ? permission_button.find(permission_upload_invoice => 
            permission_upload_invoice !== null && permission_upload_invoice.name 
            == name
            && permission_upload_invoice.allow == "true")
        : false;

        return permission;
    }

    let permission_add_group = check_permission(buttonAccess, "Add Group");
    let permission_edit_group = check_permission(buttonAccess, "Edit Group");
    let permission_delete_group = check_permission(buttonAccess, "Delete Group");

    if(permission_add_group && permission_add_group.allow === "true"){
        permission_add_group = true;
    }else {
        permission_add_group = false;
    }

    if(permission_edit_group && permission_edit_group.allow === "true"){
        permission_edit_group = true;
    }else {
        permission_edit_group = false;
    }
    if(permission_delete_group && permission_delete_group.allow === "true"){
        permission_delete_group = true;
    }else {
        permission_delete_group = false;
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }
    
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Group</Title>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        {permission_add_group && (
                                            <Link to="/group/create">
                                                <Button type="primary"><PlusOutlined />Add</Button>
                                            </Link>
                                        )}
                                        <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                    </Space>
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table
                                    columns={columns}
                                    dataSource={groups.data}
                                    rowKey={(data) => data._id}
                                    onChange={handleTableChange}
                                    pagination={{ pageSize: 10, current: page, total: groups.total, showSizeChanger: false }}
                                    loading={loading}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Group